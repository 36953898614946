import Button from '../fabrique/Button';
import Menu from '../fabrique/Menu';
import MenuNavbarItem from '../fabrique/MenuNavbarItem';
import MenuNavbarItems from '../fabrique/MenuNavbarItems';
import MenuNavbarToggle from '../fabrique/MenuNavbarToggle';
import MenuOverlayItem from '../fabrique/MenuOverlayItem';
import MenuOverlayItems from '../fabrique/MenuOverlayItems';
import MenuOverlaySection from '../fabrique/MenuOverlaySection';

const MenuOverlay = () => (
  <MenuOverlayItems>
    <MenuOverlaySection type="large">
      <MenuOverlayItem href="/" label="Home" />
    </MenuOverlaySection>
    <MenuOverlaySection type="regular">
      <MenuOverlayItem href="/onze-mensen" label="Onze mensen" />
      <MenuOverlayItem href="/afdeling" label="Onze afdeling" />
      <MenuOverlayItem href="/nieuws" label="Nieuws" />
      <MenuOverlayItem href="/agenda" label="Agenda" />
      <MenuOverlayItem href="https://groenlinks.nl" label="Naar GroenLinks.nl" />
    </MenuOverlaySection>
    <MenuOverlaySection type="footer">
      <MenuOverlayItem href="https://mijn.groenlinks.nl" label="Mijn GroenLinks" />
    </MenuOverlaySection>
  </MenuOverlayItems>
);

const MenuNavbarLeft = ({ setOverlay }) => (
  <MenuNavbarItems side="left" >
    <MenuNavbarToggle setOverlay={setOverlay} />
  </MenuNavbarItems>
)

const MenuNavbarRight = () => (
  <MenuNavbarItems side="right" >
    <MenuNavbarItem href="https://groenlinks.nl/word-lid" label="Word lid" />
    <Button href="https://groenlinks.nl/doe-mee" label="Doe mee" />
  </MenuNavbarItems>
)

const AppMenu = ({ websiteName }) => (
  <Menu
    menuOverlay={MenuOverlay}
    menuNavbarLeft={MenuNavbarLeft}
    menuNavbarRight={MenuNavbarRight}
    websiteName={websiteName}
  />
);

export default AppMenu;
