import React from 'react';

import { Picture } from 'react-responsive-picture';

const CarouselSlide = ({
  date,
  href,
  image,
  imageSources,
  primaryTag,
  secondaryTag,
  text,
  label,
}) => (
  <div className="card-slider">
    <a className="card " href={href}>
      <div className="card__image">
        <span className="picture picture--background">
          <Picture
            alt={label}
            className="picture__image"
            sources={imageSources}
            src={image}
          />
        </span>
      </div>
      <div className="card__content">
        {(primaryTag || secondaryTag) && (
          <div className="card__tags">
            {primaryTag && <span className="tag tag--strong ">{primaryTag}</span>}
            {primaryTag && secondaryTag && <span className="card__tag-separator">・</span>}
            {secondaryTag && <span className="tag tag--default ">{secondaryTag}</span>}
          </div>
        )}
        <p className="card__title">{label}</p>
        <div className="card__text">{text}</div>
        {date && <p className="card__caption"><span className="card__caption-date">{date}</span></p>}
        <span className="link link--tertiary " tabIndex="0">
          <span className="link__span">Lees meer</span>
          <span className="link__icon link__icon--after">
            <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
              <path fill-rule="evenodd" d="M6 13.29L10.5 9 6 4.71 6.75 4 12 9l-5.25 5z"></path>
            </svg>
          </span>
        </span>
      </div>
    </a>
  </div>
);

export default CarouselSlide;
