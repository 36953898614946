import React from 'react';

import Link from './Link';

const MenuNavbarItem = (props) => (
  <span className="menu-bar__link">
    <Link {...props} />
  </span>
);

export default MenuNavbarItem;
