import React from 'react';

const Person = ({
  href,
  image,
  name,
  title,
}) => (
  <a className="person-card " href={href}>
    <div className="person-card__image">
      <picture className="picture">
        <img
          src={image}
          className="picture__image"
        />
      </picture>
    </div>
    <div className="person-card__content">
      <p className="person-card__title">
        <strong className="person-card__title-span">{name}</strong>
      </p>
      <p className="person-card__text">{title}</p>
    </div>
  </a>
);

export default Person;
