import React from 'react';

const FollowButton = ({
  children,
  href,
  label,
}) => (
  <a className="icon-button icon-button--round " href={href} target="_blank" aria-label={label}>
    <span className="icon-button__icon">
      {children}
    </span>
  </a>
);

export default FollowButton;
