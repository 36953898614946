import packageJson from '../../package.json';
import logo from '../styles/images/logo.svg';

const MenuNavbarLogo = ({
  websiteName,
}) => (
  <div className="menu-bar__logo">
    <a className="logo " href="/" title="GroenLinks" aria-label="GroenLinks">
      <span className="logo__span" hidden="screen">GroenLinks</span>
      <img className="logo logo__svg svg" src={logo} alt="logo" />
      <p className="menu-bar__department-title">{websiteName}</p>
    </a>
  </div>
);

export default MenuNavbarLogo;
