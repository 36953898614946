import React from 'react';

const SplitContent = ({
  className,
  leftText,
  leftLabel,
  rightText,
  rightLabel,
  size,
}) => (
  <div className={`campaign-spread-${size} campaign-spread-split ${className || '' }`}>
    <div className={`campaign-spread-${size}__grid`}>
      <div className={`campaign-spread-${size}__content campaign-spread-split__left`}>
        <h3 className={`campaign-spread-${size}__title`}>{leftLabel}</h3>
        <p className={`campaign-spread-${size}__introduction`}>
          {leftText}
        </p>
      </div>
      <span className="campaign-spread-split__divider" />
      <div className={`campaign-spread-${size}__content campaign-spread-split__right`}>
        <h3 className={`campaign-spread-${size}__title`}>{rightLabel}</h3>
        <p className={`campaign-spread-${size}__introduction`}>
          {rightText}
        </p>
      </div>
    </div>
  </div>
);

SplitContent.defaultProps = {
  size: 'medium',
}

export default SplitContent;
