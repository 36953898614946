import React from 'react';
import Flickity from 'react-flickity-component';

import 'flickity/css/flickity.css';

import CarouselSlide from './CarouselSlide';

const Carousel = ({
  label,
  slides,
  slideComponent: SlideComponent,
}) => (
  <div className="card-carousel">
    <div className="card-carousel__grid">
      {label && <h3 className="card-carousel__title">{label}</h3>}
      <div className="card-carousel__carousel" >
        <Flickity
          options={{
            cellAlign: "left",
            pageDots: false,
            prevNextButtons: false,
          }}
          className="swiper-contaner"
        >
          {slides.map((options, index) => <SlideComponent key={`slide-${index}`} {...options} />)}
        </Flickity>
      </div>
    </div>
  </div>
);

Carousel.defaultProps = {
  slideComponent: CarouselSlide,
}

export default Carousel;
