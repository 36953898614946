const Button = ({ href, label, type }) => (
  <a className={`button button--${type}`} href={href} aria-label={label}>
    <span className="button__span">{label}</span>
  </a>
);

Button.defaultProps = {
  type: 'secondary'
}

export default Button;
