import DefaultImage from './images/default.jpg';
import ArthurImage from './images/arthur.JPG';
import VanDerLeeImage from './images/Tom.jpg';
import JesseImage from './images/jesse.jpg';

const supporters = {
  1: {
    href: '?person=1',
    name: 'Jesse Klaver',
    text: 'De afgelopen vier jaar hebben mijn fractie en ik keihard gewerkt om de idealen van GroenLinks mogelijk te maken. Maar onze handen jeuken om de komende vier jaar direct aan de knoppen te zitten. Help ons mee om de grootste partij van Nederland te worden!',
    title: 'Lijsttrekker',
    image: JesseImage,
    recruited: [2],
  },
  2: {
    href: '?person=2',
    name: 'Arthur Dingemans',
    text: 'Ik vind het heel belangrijk om snel actie te nemen om de opwarming van de aarde te beperken. Alleen GroenLinks heeft een programma dat echt ambitieus en realistisch is. Help mee door geld te doneren en het woord verder te verspreiden!',
    title: 'Supporter',
    image: ArthurImage,
    recruited: [3, 4, 5],
    recruitedBy: 1,
  },
  3: {
    href: '?person=3',
    name: 'Piet',
    text: 'Ik steun GroenLinks!',
    title: 'Supporter',
    image: DefaultImage,
    recruitedBy: 2,
  },
  4: {
    href: '?person=4',
    name: 'Tom van der Lee',
    text: 'Ik steun GroenLinks!',
    title: 'Kandidaat',
    image: VanDerLeeImage,
    recruitedBy: 2,
  },
  5: {
    href: '?person=5',
    name: 'Jan Janssen',
    text: 'Ik steun GroenLinks!',
    title: 'Supporter',
    image: DefaultImage,
    recruitedBy: 2,
  },
};

export default supporters;
