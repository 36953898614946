import React from 'react';
import Person from '../fabrique/Person';

const PersonSlide = (props) => (
  <div className="card-slider">
    <Person {...props} />
  </div>
);

export default PersonSlide;
