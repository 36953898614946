import React from 'react';

import Form from '../fabrique/Form';
import FormLegend from '../fabrique/FormLegend';
import FormRow from '../fabrique/FormRow';

const DonationForm = () => {
  const [email, setEmail] = React.useState('');

  return (
    <Form
      id="doneer"
      onSubmit={(e) => {
        alert("Formulier ingediend!");
        e.preventDefault();
      }}
    >
      <FormLegend label="Personalia" />
      <FormRow>
        <div
          className="text-field text-field--textfield text-field--required text-field--empty text-field--fancy-labels">
          <label htmlFor="id_given_name" className="text-field__label">Voornaam</label>
          <div className="text-field__wrapper">
            <input id="id_given_name" className="text-field__input" type="text" name="given_name" value=""
                   required="" autoComplete="chrome-off-69513105" />
          </div>
        </div>
        <div
          className="text-field text-field--textfield text-field--required text-field--empty text-field--fancy-labels">
          <label htmlFor="id_family_name" className="text-field__label">Achternaam</label>
          <div className="text-field__wrapper">
            <input id="id_family_name" className="text-field__input" type="text" name="family_name" value=""
                   required="" autoComplete="chrome-off-69513105"/>
          </div>
        </div>
      </FormRow>
      <FormRow>
        <div className="text-field text-field--email text-field--empty text-field--fancy-labels">
          <label htmlFor="id_email_address" className="text-field__label">E-mailadres</label>
          <div className="text-field__wrapper">
            <input id="id_email_address" className="text-field__input" type="email" name="email_address" value=""
                   autoComplete="chrome-off-69513105" />
          </div>
        </div>
      </FormRow>
      <FormRow>
        <div className="option-field option-field--checkbox">
          <div className="option-field__wrapper">
            <div className="option-field__item">
              <input id="id_news_letter_news_letter" className="option-field__input" type="checkbox" name="news_letter[news_letter]" value="news_letter" />
              <label className="option-field__input-label" htmlFor="id_news_letter_news_letter">
                <span className="option-field__input-label-span">
                  Houd me op de hoogte van GroenLinks en Jesse Klaver.<br /><br />
                  Wil je weten hoe GroenLinks je persoonsgegevens verwerkt? Bekijk dan onze <a href="/privacy">privacyverklaring</a>.
                </span>
                <span className="option-field__icon option-field__icon-background" />
                <span className="option-field__icon option-field__icon-foreground">
                <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
                  <path fill-rule="evenodd" d="M4.21 8.63l3.29 3.39 6.29-6.52.71.74-7 7.26-4-4.13z" />
                </svg>
              </span>
              </label>
            </div>
          </div>
        </div>
      </FormRow>
      <FormLegend label="Doneergegevens" />
          <div className="form-row form-row--default donation-row">
            <div className="donation-row__buttons">
              <div className="donation-row__buttons-wrapper">
                <button className="button button--secondary  donation-row__button" type="button" data-option="3"
                        aria-label="€ 3">
                  <span className="button__span">€ 3</span>
                </button>
                <button className="button button--secondary  donation-row__button" type="button" data-option="5"
                        aria-label="€ 5">
                  <span className="button__span">€ 5</span>
                </button>
                <button className="button button--secondary  donation-row__button" type="button" data-option="10"
                        aria-label="€ 10">
                  <span className="button__span">€ 10</span>
                </button>
                <button className="button button--secondary  donation-row__button" type="button" data-option="25"
                        aria-label="€ 25">
                  <span className="button__span">€ 25</span>
                </button>
              </div>
            </div>
            <div className="form-row form-row--default ">
              <div className="text-field text-field--textfield text-field--empty text-field--fancy-labels">
                <label htmlFor="id_amount[other]" className="text-field__label">€ 75</label>
                <div className="text-field__wrapper">
                  <input id="id_amount[other]" className="text-field__input" type="text" name="amount[other]" value=""
                         autoComplete="chrome-off-69513105" />
                </div>
              </div>
            </div>
          </div>
          <input type="hidden" name="amount[radios]" value="_other_" />
          <div className="form-row form-row--default ">
            <div
              className="dropdown-field dropdown-field--required dropdown-field--empty dropdown-field--fancy-labels">
              <label htmlFor="id_issuer" className="dropdown-field__label">Selecteer bank</label>
              <div className="dropdown-field__wrapper">
                <div className="dropdown-field__container">
                  <select id="id_issuer" className="dropdown-field__input" name="issuer" required="">
                    <option value=""></option>
                    <option value="ABNANL2A">ABN AMRO</option>
                    <option value="SNSBNL2A">SNS</option>
                    <option value="TRIONL2U">Triodos Bank</option>
                    <option value="FVLBNL22">Van Lanschot</option>
                    <option value="ASNBNL21">ASN Bank</option>
                    <option value="BUNQNL2A">bunq</option>
                    <option value="HANDNL2A">Handelsbanken</option>
                    <option value="INGBNL2A">ING</option>
                    <option value="KNABNL2H">Knab</option>
                    <option value="MOYONL21">Moneyou</option>
                    <option value="RABONL2U">Rabobank</option>
                    <option value="RBRBNL21">RegioBank</option>
                  </select>
                  <span className="dropdown-field__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="18" height="18" viewBox="0 0 18 18" fill="currentColor">
                      <path fill-rule="evenodd" d="M13.29 6L9 10.5 4.71 6 4 6.75 9 12l5-5.25z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <input data-drupal-selector="edit-transaction-id" type="hidden" name="transaction_id" value="" />
          <input data-drupal-selector="edit-transaction-status" type="hidden" name="transaction_status" value="" />
          <fieldset className="form-fieldset ">
            <div className="form-row form-row--default ">
              <div className="form-row form-row--footer ">
                <button className="button button--primary  " type="submit" aria-label="Doneren 💸">
                  <span className="button__span">Doneren 💸</span>
                </button>
              </div>
            </div>
          </fieldset>
          <input autoComplete="chrome-off-69513105" data-drupal-selector="form-msp5wunq-hrg4gk1hgfnrur2zfhzji5od4buuesh4mq" type="hidden" name="form_build_id" value="form-msp5wUnq-hrg4gK1HgFnRuR2ZfhzjI5Od4BuuESH4mQ" />
          <input
            data-drupal-selector="edit-webform-submission-donatie-formulier-landelijk-paragraph-408954-add-form"
            type="hidden" name="form_id"
            value="webform_submission_donatie_formulier_landelijk_paragraph_408954_add_form" />
    </Form>
  );
};

export default DonationForm;
