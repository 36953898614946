import React from 'react';

import Footer from '../fabrique/Footer';
import FooterColumn from '../fabrique/FooterColumn';
import Link from '../fabrique/Link';
import LinkList from '../fabrique/LinkList';

const AppMenu = () => (
  <Footer>
    <FooterColumn>
      <LinkList>
        <Link href="https://groenlinks.nl/missie" label="Onze missie" />
        <Link href="https://groenlinks.nl/standpunten" label="Standpunten" />
        <Link href="https://groenlinks.nl/onze-mensen" label="Onze mensen" />
      </LinkList>
    </FooterColumn>
    <FooterColumn>
      <LinkList>
        <Link href="https://groenlinks.nl/lokaal" label="Lokaal" />
        <Link href="https://groenlinks.nl/onze-partij" label="Onze partij" />
        <Link href="https://groenlinks.nl/nieuws" label="Nieuws" />
      </LinkList>
    </FooterColumn>
    <FooterColumn>
      <LinkList>
        <Link href="https://werkenbij.groenlinks.nl/" label="Werken bij" />
        <Link href="https://groenlinks.nl/persinformatie" label="Pers" />
        <Link href="https://groenlinks.nl/contact" label="Contact" />
      </LinkList>
    </FooterColumn>
  </Footer>
);

export default AppMenu;
