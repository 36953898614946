import React from 'react';
import { Picture } from 'react-responsive-picture';
import Button from './Button';

const Spread = ({
  buttonHref,
  buttonLabel,
  children,
  className,
  imageComponent: ImageComponent,
  image,
  imageSources,
  size,
  text,
  label,
}) => (
  <div className={`campaign-spread-${size} ${className || '' }`}>
    <div className={`campaign-spread-${size}__grid`}>
      <div className={`campaign-spread-${size}__image`}>
        <div className={`campaign-spread-${size}__image-container`}>
          {ImageComponent ? <ImageComponent /> : (
            <Picture
              className="picture__image"
              sources={imageSources}
              src={image}
            />
          )}
        </div>
      </div>
      <div className={`campaign-spread-${size}__content`}>
        <h3 className={`campaign-spread-${size}__title`}>{label}</h3>
        {(
          children ? children : (
            <p className={`campaign-spread-${size}__introduction`}>
              {text}
            </p>
          )
        )}
        {buttonLabel && (
          <div className={`campaign-spread-${size}__buttons`}>
            <Button type="primary" href={buttonHref} label={buttonLabel} />
          </div>
        )}
      </div>
    </div>
  </div>
);

Spread.defaultProps = {
  size: 'medium',
}

export default Spread;
