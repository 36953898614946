const Form = ({
  children,
  ...props
}) => (
  <form className="form-container" {...props}>
    <div className="form-container__wrapper">
      <fieldset className="form-fieldset ">
        {children}
      </fieldset>
    </div>
  </form>
);

export default Form;
