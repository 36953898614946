import React from 'react';
import { useCountUp } from 'react-countup';

const Count = ({
  value,
  valuePrefix,
  max,
  suffix,
  title,
}) => {
  const { countUp } = useCountUp({ end: value });

  return (
    <div className="campaign-spread__count campaign-spread__count--visible">
      <div className="campaign-spread__wrapper">
        <p className="campaign-spread__count-value">{valuePrefix}{countUp}</p>
        <div className="campaign-spread__count-target">
          <p className="campaign-spread__count-title">{title}</p>
          <progress
            className="campaign-spread__count-progress"
            max={max}
            value={countUp === 0 ? 0 : value}
            data-value={value}
            aria-label={`${value/max}%`}
          >
            {countUp}
          </progress>
        </div>
      </div>
      {suffix && (
        <div className="campaign-spread__count-suffix">{suffix}</div>
      )}
    </div>
  );
};

export default Count;
