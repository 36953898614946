import menu from '../styles/images/menu.svg';
import menu_close from '../styles/images/menu-close.svg';

const MenuNavbarToggle = ({ setOverlay }) => (
  <div className="menu-bar__icon-button menu-bar__icon-button--menu">
    <button
      className="icon-button icon-button--default button--menu"
      aria-label="Menu"
      onClick={() => setOverlay(true)}
    >
        <span className="icon-button__icon">
          <img className="svg" src={menu} />
        </span>
    </button>
    <button
      className="icon-button icon-button--default button--close"
      aria-label="Sluiten"
      onClick={() => setOverlay(null)}
    >
        <span className="icon-button__icon">
          <img className="svg" src={menu_close} />
        </span>
    </button>
  </div>
);

export default MenuNavbarToggle;
