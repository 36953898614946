import React from 'react';

const Link = ({ href, label, type }) => (
  <a className={`link link--${type}`} href={href}>
    <span className="link__span">{label}</span>
  </a>
);

Link.defaultProps = {
  type: 'default',
};

export default Link;
