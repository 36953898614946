import React from 'react';

const FormTextField = ({
  children,
  id,
  label,
  required,
  type,
  value,
  onChange,
}) => {
  const [focussed, setFocussed] = React.useState(false);
  const className = [
    'text-field',
    'text-field--fancy-labels',
    focussed ? 'text-field--focussed' : '',
    required ? 'text-field--required' : '',
    value ? '' : 'text-field--empty',
  ].join(' ');

  return (
    <div className={className}>
      <label htmlFor={id} className="text-field__label">{label}</label>
      <div className="text-field__wrapper">
        <input
          id={id}
          className="text-field__input"
          type={type}
          value={value}
          required=""
          onBlur={() => setFocussed(false)}
          onChange={onChange}
          onFocus={() => setFocussed(true)}
        />
      </div>
      {children}
    </div>
  );
}

FormTextField.defaultProps = {
  type: 'text',
};

export default FormTextField
