import FontAwesome from 'react-fontawesome';
import React from 'react';
import { useParams } from "react-router-dom";

import DonationForm from '../components/DonationForm';
import PersonSlide from '../components/PersonSlide';
import SplitContent from '../components/SplitContent';
import SupporterForm from '../components/SupporterForm';
import Carousel from '../fabrique/Carousel';
import Count from '../fabrique/Count';
import FeaturedCampaign from '../fabrique/FeaturedCampaign';
import Link from '../fabrique/Link';
import Main from '../fabrique/Main';
import Person from '../fabrique/Person';
import Spread from '../fabrique/Spread';
import gesprekImage from '../images/gesprek.jpg';
import gesprekImage0_1 from '../images/gesprek_0-1.jpg';
import gesprekImage0_2 from '../images/gesprek_0-2.jpg';
import milaImage from '../images/mila.jpg';
import milaImage1580 from '../images/mila_1580.jpg';
import milaImage0 from '../images/mila_0.jpg';
import donationImage from '../images/vrijwilligers.png';
import supporters from '../supporters';
import themes from '../themes';

const HomePage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const personId = urlParams.get('person') || 1;
  const person = supporters[personId];
  const recruitedBy = person.recruitedBy && supporters[person.recruitedBy];

  return (
    <React.Fragment>
      <Main>
        <div className="header header--default" />
        <Spread
          className="campaign-spread-person"
          imageComponent={() => (
            <Person
              name={person.name}
              title={person.title}
              image={person.image}
            />
          )}
          text={(
            <React.Fragment>
              <FontAwesome name="quote-left" />
              {person.text}
              <FontAwesome name="quote-right" />
              {recruitedBy && (
                <div style={{marginTop: '1em', fontSize: '80%'}}>
                  Supporter geworden via <Link href={`?person=${person.recruitedBy}`} label={recruitedBy.name} type="related-content" />
                </div>
              )}
            </React.Fragment>
          )}
          label="Ik ben supporter van GroenLinks"
        />
        <SplitContent
          leftLabel="Mijn impact"
          leftText={(
            <span>
            <Count
              valuePrefix="€"
              value={100}
              max={1000}
              suffix="En €10.000 indirect"
              title="Aan donaties opgehaald"
            />
            <Count
              value={5}
              max={10}
              suffix="En 12 indirect"
              title="Online Campaigners geworven"
            />
            <Count
              value={0}
              max={10}
              suffix="En 6 indirect"
              title="Supporters geworven"
            />
          </span>
          )}
          rightLabel="Wat kan jij doen?"
          rightText={(
            <ul>
              <li><a href="#doneer">Geef een donatie</a></li>
              <li><a href="#onlineCampaigner">Word Online Campaigner</a></li>
              <li><a href="#supporter">Maak je eigen Supporters pagina</a></li>
            </ul>
          )}
        />
        <Carousel
          label="Mijn favoriete GroenLinks standpunten"
          slides={Object.values(themes)}
        />
        <Spread label="Geef een donatie" image={donationImage}>
          <DonationForm />
        </Spread>
        <Spread
          buttonHref="https://groenlinks.nl/word-online-campaigner"
          buttonLabel="Ja, ik meld me aan"
          imageSources={[
            {
              srcSet: `${milaImage1580} 1x`,
              media: "(min-width: 1580px)",
              type: "image/jpeg",
            },
            {
              srcSet: `${milaImage0} 2x`,
              media: "(min-width: 0px)",
              type: "image/jpeg",
            }]
          }
          image={milaImage}
          label={<span id="onlineCampaigner">Word online campaigner</span>}
          text={<span>Jij bent nodig bij de belangrijkste campagne die we ooit gevoerd hebben. Meld je nu aan als&nbsp;<strong>online campaigner.</strong></span>}
        />
        <Carousel
          label="Supporter geworden via mij"
          slides={(person.recruited || []).map((p) => supporters[p])}
          slideComponent={PersonSlide}
        />
        <FeaturedCampaign
          image={gesprekImage}
          imageSource={[
            {
              srcSet: `${gesprekImage0_1} 1x, ${gesprekImage0_2} 2x`,
              media: "(min-width: 0px)",
              type: "image/jpeg",
            }
          ]}
          label={<span id="supporter">Maak je eigen Supporters pagina</span>}
          text="Maak snel jouw pagina aan om te laten zien dat je GroenLinks steunt!"
        >
          <SupporterForm />
        </FeaturedCampaign>
      </Main>
    </React.Fragment>
  );
}

export default HomePage;
