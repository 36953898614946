import React from 'react';
import { Picture } from 'react-responsive-picture';

const FeaturedCampaign = ({
  children,
  image,
  imageSources,
  label,
  text,
}) => (
  <div className="featured-campaign featured-campaign--inline-form">
    <div className="featured-campaign__grid">
      <div className="featured-campaign__image">
        <Picture src={image} sources={imageSources} />
      </div>
      <div className="featured-campaign__content">
        <h1 className="featured-campaign__title">{label}</h1>
        <p className="featured-campaign__text">
          {text}
        </p>
        {children}
      </div>
    </div>
  </div>
);

export default FeaturedCampaign;
