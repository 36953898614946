import React from 'react';

import Form from '../fabrique/Form';
import FormSubmitButton from '../fabrique/FormSubmitButton';
import FormTextField from '../fabrique/FormTextField';
import FormRow from '../fabrique/FormRow';

const SupporterForm = () => {
  const [email, setEmail] = React.useState('');

  return (
    <Form
      onSubmit={(e) => {
        alert("Formulier ingediend!");
        e.preventDefault();
      }}
    >
      <FormRow>
        <FormTextField
          id="id_email_address"
          label="E-mailadres"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormRow>
      <p>Wil je weten hoe GroenLinks je persoonsgegevens verwerkt? Bekijk dan onze <a href="/privacy">privacyverklaring</a>.</p>
      <FormRow type="footer">
        <FormSubmitButton label="Maak pagina aan" />
      </FormRow>
    </Form>
  );
}

export default SupporterForm;
