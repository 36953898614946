import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import PageWrapper from '../fabrique/PageWrapper';
import Routes from '../routes';
import supporters from '../supporters';

import AppMenu from './AppMenu';
import AppFooter from './AppFooter';

const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const personId = urlParams.get('person') || 1;
  const person = supporters[personId];

  return (
    <Router>
      <PageWrapper>
        <AppMenu websiteName={person.name} />
        <Routes />
        <AppFooter />
      </PageWrapper>
    </Router>
  );
}

export default App;
