const FormRow = ({
  children,
  type,
}) => (
  <div className={`form-row form-row--${type}`}>
    {children}
  </div>
);

FormRow.defaultProps = {
  type: 'default',
};

export default FormRow;
