import React from 'react';

import logo from '../styles/images/logo.svg';

import FollowButtons from './FollowButtons';
import FooterColumn from './FooterColumn';
import Link from './Link';

const Footer = ({
  children,
}) => (
  <footer className="container container--footer" role="contentinfo">
    <div className="region region-site-footer">
      <div className="footer-bar">
        <div className="footer-bar__grid">
          <div className="footer-bar__logo">
            <a className="logo logo--default " href="https://groenlinks.nl/" title="GroenLinks" aria-label="GroenLinks">
              <span className="logo__span" hidden="screen">GroenLinks</span>
              <img className="svg" src={logo} />
            </a>
          </div>
          {children}
          <FollowButtons classPrefix="footer-bar__" />
          <div className="footer-bar__bottom-links">
            <span className="footer-bar__bottom-link">
              <Link href="https://groenlinks.nl/privacy" label="Privacy" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
