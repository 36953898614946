import AardgasImage from './images/aardgas.jpg';
import AfvalImage from './images/afval.png';
import BelastingenImage from './images/belastingdienst.jpg';

const themes = {
  1: {
    label: 'Aardgas',
    text: 'Aardgas is een fossiele brandstof. Hoewel het minder vervuilend is dan bijvoorbeeld steenkool, draagt aardgas ook bij aan het broeikaseffect.',
    image: AardgasImage,
  },
  2: {
    label: 'Belastingen',
    text: 'GroenLinks verlaagt de belasting voor mensen met een kleine beurs. Vervuilers en mensen met een hoog inkomen gaan meer belasting betalen.',
    image: BelastingenImage,
  },
  3: {
    label: 'Afval en recycling',
    text: 'Grondstoffen zoals ijzer en olie raken op. Maar nog steeds verspillen wij ontzettend veel grondstoffen, door ze als afval weg te gooien in plaats van opnieuw te gebruiken.',
    image: AfvalImage,
  },
};

export default themes;
